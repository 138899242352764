<template>
  <div>
    <div class="text-h5">Penyelesaian Masalah</div>
    <v-card-text>
      <ol class="toc">
        <li><a href="#q1">Perisian yang disokong.</a></li>
        <li><a href="#q2">Bagaimana untuk selesaikan masalah "Your connection is not private"?</a></li>
        <li><a href="#q3">Bagaimana untuk selesaikan masalah jika soalan matematik mempunyai simbol ini ▯?</a></li>
      </ol>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <ol class="list">
        <li><a class="anchor" id="q1"></a>Perisian yang disokong</li>
        <ul>
          <li>✅ Windows (Edge 16+, Chrome 56+, Firefox 51+)</li>
          <li>✅ Tablet Android</li>
          <li>✅ Telefon Android </li>
          <li>⚠️ Macbook (Chrome sahaja)</li>
          <li>⚠️ iPad (Chrome sahaja)</li>
          <li>⚠️ iPhone (Chrome sahaja)</li>
        </ul>
        <li>Bagaimana untuk selesaikan masalah "Your connection is not private"?</li>
        <ul>
          <li>Ada 3 keadaan yang menyebabkan masalah ini.</li>
          <ul>
            <li>Sudah lama tidak kemas kini sistem operasi Window</li>
            <li>Menggunakan Window 7 dan tidak kemas kini sistem operasi.</li>
            <li>Menggunakan Windows cetak rompak dan tidak boleh kemas kini sistem operasi.</li>
          </ul>
          <li><a class="anchor" id="q2"></a>Solusi：</li>
          <ol>
            <li>Muat turun fail tersebut:</li>
            <ul>
              <li><a href="https://letsencrypt.org/certs/isrgrootx1.der">Klik untuk muat turun isrgrootx1.der</a></li>
              <li><a href="https://letsencrypt.org/certs/isrg-root-x2.der">Klik untuk muat turun isrg-root-x2.der</a><a></a></li>
            </ul>
            <li>Buka isrgrootx1.der。Click【Open】。</li>
            <li>Klik【Yes】。</li>
            <li>Klik【Install Certificate...】。</li>
            <li>Klik【Next】。</li>
            <li>Pilih【Place all certificates in the following store】dan tekan【Browse...】。</li>
            <li>Pilih【Trusted Root Certification Authorities】dan tekan【OK】。</li>
            <li>Klik【Next】。</li>
            <li>Klik【Finish】。</li>
            <li>Buka isrg-root-x2.der dan ulang langkah 3 sehingga 9.</li>
            <li>Restart komputer.</li>
            <li>Siap!</li>
            <li>Jika masalah tidak selesai, anda mungkin perlu bersihkan browser cache</li>
            <ul>
              <li><a href="https://www.businessinsider.com/how-to-clear-cache-in-internet-explorer" target="_blank">Internet Explorer</a></li>
              <li><a href="https://support.google.com/accounts/answer/32050?hl=en&co=GENIE.Platform%3DDesktop" target="_blank">Chrome</a></li>
              <li><a href="https://support.microsoft.com/en-us/microsoft-edge/view-and-delete-browser-history-in-microsoft-edge-00cf7943-a9e1-975a-a33d-ac10ce454ca4" target="_blank">Edge</a></li>
              <li><a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox" target="_blank">Firefox</a></li>
            </ul>
          </ol>
          <li>Jika mahu faham isu dengan lebih lagi, anda boleh <a href="https://www.stephenwagner.com/2021/09/30/sophos-dst-root-ca-x3-expiration-problems-fix/amp/" target="_blank">klik untuk baca artikel berkaitan</a>.</li>
        </ul>
        <li><a class="anchor" id="q3"></a>Bagaimana untuk selesaikan masalah jika soalan matematik mempunyai simbol ini ▯?</li>
        <ul>
          <li>Sistem kami menggunakan emoji sebelum tahun 2018</li>
          <li>Sistem operasi lama seperti Windows 7 tidak dapat mengeluarkan emoji</li>
          <li>Jika peranti gagal untuk menunjukkan emoji tersebut, sila kemas kini sistem operasi ataupun menggunakan peranti lain.</li>
          <ul>
            <li>🥮 Mooncake (2018) </li>
            <li>🥧 Pie (2017) </li>
            <li>🥕 Carrot (2016) </li>
            <li>🦁 Lion (2015) </li>
          </ul>
        </ul>
      </ol>
    </v-card-text>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  };
</script>
<style scoped>
  /* BOC: toc */
  .toc {
    font-size: 120%;
  }
  .toc a {
    text-decoration: none;
  }
  a.anchor {
    display: block;
    position: relative;
    top: -240px;
    visibility: hidden;
  }
  /* EOC */
  /* BOC: list */
  .list {
    font-size: 120%;
  }
  .list > ol,
  .list > ul {
    margin-bottom: 30px;
  }
  .list > li {
    font-weight: bold;
    color:black;
  }
  .list li {
    margin-bottom: 8px;
  }
  /* EOC */
</style>