<template>
  <div>
    <div class="text-h5">Troubleshoot</div>
    <v-card-text>
      <ol class="toc">
        <li><a href="#q1">Supported Softwares</a></li>
        <li><a href="#q2">How to fix "Your connection is not private"?</a></li>
        <li><a href="#q3">How to fix maths question with unknown ▯ symbols?</a></li>
      </ol>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <ol class="list">
        <li><a class="anchor" id="q1"></a>Supported Softwares</li>
        <ul>
          <li>✅ Windows (Edge 16+, Chrome 56+, Firefox 51+)</li>
          <li>✅ Android tablet</li>
          <li>✅ Android phone</li>
          <li>⚠️ Macbook (Chrome only)</li>
          <li>⚠️ iPad (Chrome only)</li>
          <li>⚠️ iPhone (Chrome only)</li>
        </ul>
        <li>How to fix "Your connection is not private"?</li>
        <ul>
          <li>There are 3 possible scenarios that caused this error.</li>
          <ul>
            <li>Not update your Windows operating system for a very long time.</li>
            <li>Using Windows 7 and not able to update your operating system.</li>
            <li>Using pirated copy of Windows and not able to update your operating system.</li>
          </ul>
          <li><a class="anchor" id="q2"></a>Solution：</li>
          <ol>
            <li>Download the following files:</li>
            <ul>
              <li><a href="https://letsencrypt.org/certs/isrgrootx1.der">Click to download isrgrootx1.der</a></li>
              <li><a href="https://letsencrypt.org/certs/isrg-root-x2.der">Click to download isrg-root-x2.der</a><a></a></li>
            </ul>
            <li>Open isrgrootx1.der。Click【Open】。</li>
            <li>Click【Yes】。</li>
            <li>Click【Install Certificate...】。</li>
            <li>Click【Next】。</li>
            <li>Select【Place all certificates in the following store】then click【Browse...】。</li>
            <li>Select【Trusted Root Certification Authorities】then click【OK】。</li>
            <li>Click【Next】。</li>
            <li>Click【Finish】。</li>
            <li>Open isrg-root-x2.der and repeat step 3 to 9.</li>
            <li>Restart the computer.</li>
            <li>Done!</li>
            <li>If the issue persist, you may need to clear the browser cache.</li>
            <ul>
              <li><a href="https://www.businessinsider.com/how-to-clear-cache-in-internet-explorer" target="_blank">Internet Explorer</a></li>
              <li><a href="https://support.google.com/accounts/answer/32050?hl=en&co=GENIE.Platform%3DDesktop" target="_blank">Chrome</a></li>
              <li><a href="https://support.microsoft.com/en-us/microsoft-edge/view-and-delete-browser-history-in-microsoft-edge-00cf7943-a9e1-975a-a33d-ac10ce454ca4" target="_blank">Edge</a></li>
              <li><a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox" target="_blank">Firefox</a></li>
            </ul>
          </ol>
          <li>If you like to understand the issue more, you may <a href="https://www.stephenwagner.com/2021/09/30/sophos-dst-root-ca-x3-expiration-problems-fix/amp/" target="_blank">click here to read the related article</a>.</li>
        </ul>
        <li><a class="anchor" id="q3"></a>How to fix maths question with unknown ▯ symbols?</li>
        <ul>
          <li>Our system is using emoji before 2018.</li>
          <li>Old operating system like Windows 7 will not able to render emoji.</li>
          <li>If your device failed to read any of the following emojis, please update its operating system or use another device.</li>
          <ul>
            <li>🥮 Mooncake (2018) </li>
            <li>🥧 Pie (2017) </li>
            <li>🥕 Carrot (2016) </li>
            <li>🦁 Lion (2015) </li>
          </ul>
        </ul>
      </ol>
    </v-card-text>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  };
</script>
<style scoped>
  /* BOC: toc */
  .toc {
    font-size: 120%;
  }
  .toc a {
    text-decoration: none;
  }
  a.anchor {
    display: block;
    position: relative;
    top: -240px;
    visibility: hidden;
  }
  /* EOC */
  /* BOC: list */
  .list {
    font-size: 120%;
  }
  .list > ol,
  .list > ul {
    margin-bottom: 30px;
  }
  .list > li {
    font-weight: bold;
    color:black;
  }
  .list li {
    margin-bottom: 8px;
  }
  /* EOC */
</style>