<template>
  <v-container>
    <div class="mb-3"><a @click="$router.go(-1)">{{$t('action.back')}}</a></div>
    <TroubleshootZh v-if="$_getLocale() == 'zh'"></TroubleshootZh>
    <TroubleshootMs v-else-if="$_getLocale() == 'ms'"></TroubleshootMs>
    <TroubleshootEn v-else></TroubleshootEn>
  </v-container>
</template>

<script>
  import TroubleshootEn from "@/components/Troubleshoot/TroubleshootEn.vue";
  import TroubleshootMs from "@/components/Troubleshoot/TroubleshootMs.vue";
  import TroubleshootZh from "@/components/Troubleshoot/TroubleshootZh.vue";
export default {
	components:{
    TroubleshootEn,
    TroubleshootMs,
    TroubleshootZh
  },
  data:()=>({
    //
  }),
  created() {
    //
  },
};
</script>