<template>
  <div>
    <div class="text-h5">Troubleshoot</div>
    <v-card-text>
      <ol class="toc">
        <li><a href="#q1">可支撑的软件</a></li>
        <li><a href="#q2">Windows 显示 "Your connection is not private" 怎么办？</a></li>
        <li><a href="#q3">为什么会有些问题会出现▯▯▯格子符号？</a></li>
      </ol>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <ol class="list">
        <li><a class="anchor" id="q1"></a>可支撑的软件</li>
        <ul>
          <li>✅ Windows (Edge 16+, Chrome 56+, Firefox 51+)</li>
          <li>✅ Android tablet</li>
          <li>✅ Android phone</li>
          <li>⚠️ Macbook 需下载并使用Chrome</li>
          <li>⚠️ iPad 需下载并使用Chrome</li>
          <li>⚠️ iPhone 需下载并使用Chrome</li>
        </ul>
        <li>Windows 显示 "Your connection is not private" 怎么办？</li>
        <ul>
          <li>以下三种情况都有可能导致这问题。</li>
          <ul>
            <li>Windows 太久没更新操作系统。</li>
            <li>使用Windows 7，现今已经无法更新操作系统。</li>
            <li>使用盗版Windows，所以无法更新操作系统。</li>
          </ul>
          <li><a class="anchor" id="q2"></a>解决方法：</li>
          <ol>
            <li>下载以下两个文件。</li>
            <ul>
              <li><a href="https://letsencrypt.org/certs/isrgrootx1.der">点击这里下载 isrgrootx1.der</a></li>
              <li><a href="https://letsencrypt.org/certs/isrg-root-x2.der">点击这里下载 isrg-root-x2.der</a><a></a></li>
            </ul>
            <li>开启 isrgrootx1.der。点击【Open】。</li>
            <li>点击【Yes】。</li>
            <li>点击【Install Certificate...】。</li>
            <li>点击【Next】。</li>
            <li>选择【Place all certificates in the following store】后点击【Browse...】。</li>
            <li>选择【Trusted Root Certification Authorities】后点击【OK】。</li>
            <li>点击【Next】。</li>
            <li>点击【Finish】。</li>
            <li>开启 isrg-root-x2.der。重复步骤3至9。</li>
            <li>重启电脑。</li>
            <li>完成了！</li>
            <li>如果问题依然存在，需要清除浏览器的缓存。请查阅相关支援网站。</li>
            <ul>
              <li><a href="https://www.businessinsider.com/how-to-clear-cache-in-internet-explorer" target="_blank">Internet Explorer</a></li>
              <li><a href="https://support.google.com/accounts/answer/32050?hl=en&co=GENIE.Platform%3DDesktop" target="_blank">Chrome</a></li>
              <li><a href="https://support.microsoft.com/en-us/microsoft-edge/view-and-delete-browser-history-in-microsoft-edge-00cf7943-a9e1-975a-a33d-ac10ce454ca4" target="_blank">Edge</a></li>
              <li><a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox" target="_blank">Firefox</a></li>
            </ul>
          </ol>
          <li>如果想了解更深入，可以<a href="https://www.stephenwagner.com/2021/09/30/sophos-dst-root-ca-x3-expiration-problems-fix/amp/" target="_blank">查看解说</a>。</li>
        </ul>
        <li><a class="anchor" id="q3"></a>为什么会有些问题会出现▯▯▯格子符号？</li>
        <ul>
          <li>Hamochi正使用2018年及以前的图文。</li>
          <li>旧的电脑操作系统（如Windows 7）会无法读取现代的图文。</li>
          <li>如果你的电脑或手机无法看到以下的符号，可能是你的电脑或手机太久没更新软件了，建议使用其他电脑或手机。</li>
          <ul>
            <li>🥮 月饼 (最后更新于2018年) </li>
            <li>🥧 馅饼 (最后更新于2017年) </li>
            <li>🥕 萝卜 (最后更新于2016年) </li>
            <li>🦁 狮子 (最后更新于2015年) </li>
          </ul>
        </ul>
      </ol>
    </v-card-text>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  };
</script>
<style scoped>
  /* BOC: toc */
  .toc {
    font-size: 120%;
  }
  .toc a {
    text-decoration: none;
  }
  a.anchor {
    display: block;
    position: relative;
    top: -240px;
    visibility: hidden;
  }
  /* EOC */
  /* BOC: list */
  .list {
    font-size: 120%;
  }
  .list > ol,
  .list > ul {
    margin-bottom: 30px;
  }
  .list > li {
    font-weight: bold;
    color:black;
  }
  .list li {
    margin-bottom: 8px;
  }
  /* EOC */
</style>